import { SupportedCountries } from "@/enums/internationalization";

export enum TableCellType {
  Text = "text",
  CapitalizeText = "capitalizeText",
  YesNo = "yesNo",
  React = "react",
  Date = "date",
  DateSeparateLines = "date_split",
  Duration = "duration",
  Number = "number",
  Phone = "phone",
  Image = "image",
  Link = "link",
}

export type HeaderType<T extends string> = {
  name: T;
  label: string | JSX.Element;
  type: TableCellType;
};

export type TableDataType<T extends string> = Partial<
  Record<T | "action", any>
>;

export type TableDataTypeId<T extends string> = Partial<
  Record<T | "action" | "id", any>
>;

export type CustomRenderFunctionType<T extends string> = (
  header: HeaderType<T>,
  data: TableDataType<T>,
  isCell: boolean,
  key: string
) => JSX.Element;

export type CustomRenderType<T extends string> = Partial<
  Record<T, CustomRenderFunctionType<T>>
>;

export type TableProps<T extends string> = {
  headers: HeaderType<T>[];
  data: TableDataTypeId<T>[] | null;
  commonTranslation: (key: string) => string;
  country: SupportedCountries;
  customRender?: CustomRenderType<T>;
  showCheckboxes?: boolean;
  selectedItems?: string[];
  setSelectedItems?: (items: string[]) => void;
  noDataText?: string;
  noDataIcons?: JSX.Element;
  keyFormatter?: (data: TableDataTypeId<T>) => string;
  showIndex?: boolean;
  cellClassName?: string;
};
