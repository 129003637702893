import { PUBLIC_ENV_VARS } from "./environment";

export const BASE_GAIL =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://gail.lula.com"
    : PUBLIC_ENV_VARS.ENVIRONMENT === "staging"
    ? "https://gail.staging-lula.com"
    : "http://localhost:3000";

export const BASE_GAIL_CLOUD =
  PUBLIC_ENV_VARS.ENVIRONMENT === "production"
    ? "https://gail.lula.com"
    : "https://gail.staging-lula.com";
