import clsx from "clsx";
import { Table } from "flowbite-react";
import {
  HeaderType,
  TableDataType,
  CustomRenderType,
  CustomRenderFunctionType,
  TableCellType,
} from "../../../types/common/tableTypes";
import {
  ToDateLabel,
  ToDateTimeLabel,
  ToNumberLabel,
  ToSecondsLabel,
} from "@lula-technologies-inc/lul";
import Image from "next/image";
import { SplitAndCapitalize } from "@/utils/common/general";
import { GetPhoneNumberForUI } from "@/utils/common/phone";
import { BASE_GAIL } from "@/utils/common/baseUrls";
import { SupportedCountries } from "@/enums/internationalization";

export function ProcessContent<T extends string>(
  key: string,
  header: HeaderType<T>,
  data: TableDataType<T>,
  isCell: boolean,
  commonTranslation: (key: string) => string,
  country: SupportedCountries,
  customRender?: CustomRenderType<T>,
  cellClassName?: string
): JSX.Element {
  if (customRender && customRender[header.name]) {
    return (customRender[header.name] as CustomRenderFunctionType<T>)(
      header,
      data,
      isCell,
      key
    );
  }

  let content = data[header.name];

  switch (header.type) {
    case TableCellType.Date:
    case TableCellType.DateSeparateLines:
      let date = data[header.name]
        ? data[header.name].includes("T")
          ? ToDateTimeLabel(data[header.name])
          : ToDateLabel(data[header.name] + "T00:00:00")
        : "-";

      if (header.type === TableCellType.DateSeparateLines) {
        const components = (date || "").split(" ").map((c) => {
          if (c && c.length > 0 && Number.isNaN(Number(c[0]))) {
            return commonTranslation(c.toLowerCase());
          }

          return c;
        });

        if (
          date?.toLowerCase().includes("today") ||
          date?.toLowerCase().includes("yesterday")
        ) {
          content = (
            <div key={key} className={clsx("min-w-[100px]")}>
              {`${components[0]} `}
              <br className={clsx("hidden", "md:block")} />
              {components.slice(1).join(" ")}
            </div>
          );

          break;
        }

        if (components.length === 6) {
          content = (
            <div key={key} className={clsx("min-w-[100px]")}>
              {`${components[1]} ${components[2]} ${components[3]} `}
              <br className={clsx("hidden", "md:block")} />
              {`${components[4]} ${components[5]}`}
            </div>
          );

          break;
        }
      }

      content = (
        <div key={key} className={clsx("min-w-[100px]")}>
          {date}
        </div>
      );
      break;
    case TableCellType.YesNo:
      let truthyValue =
        content === "yes" || content === true || content === "true";
      content = (
        <div
          key={key}
          className={clsx(
            "rounded-full",
            "text-center",
            "px-2.5",
            "py-0.5",
            "text-xs",
            "font-semibold",
            {
              "text-green-600": truthyValue,
              "bg-green-100": truthyValue,
              "text-red-600": !truthyValue,
              "bg-red-50": !truthyValue,
            }
          )}
        >
          {truthyValue ? "Yes" : "No"}
        </div>
      );
      break;
    case TableCellType.Duration:
      content = (
        <span key={key}>{content === 0 ? "-" : ToSecondsLabel(content)}</span>
      );
      break;
    case TableCellType.Number:
      content = (
        <span key={key}>
          {content === 0 || content === null
            ? "-"
            : ToNumberLabel(content as number)}
        </span>
      );
      break;
    case TableCellType.Phone:
      content = <span key={key}>{GetPhoneNumberForUI(content, country)}</span>;
      break;
    case TableCellType.Link:
      let link = content;

      if (link.length > 0 && !link.includes("http")) {
        link = `${BASE_GAIL}${link}`;
      }

      content = (
        <a
          key={key}
          href={link}
          className={clsx("text-orange-600", "underline", "capitalize")}
          target="_blank"
        >
          {content}
        </a>
      );
      break;
    case TableCellType.Image:
      content =
        content && content.length > 0 ? (
          <Image src={content} key={key} alt="tbi" width={32} height={32} />
        ) : (
          <></>
        );
      break;
    case TableCellType.CapitalizeText:
      content = SplitAndCapitalize(content);
      break;
  }

  if (isCell) {
    return (
      <Table.Cell key={key} className={cellClassName}>
        {content}
      </Table.Cell>
    );
  }

  return <span key={key}>{content}</span>;
}
