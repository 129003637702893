export enum SupportedLanguages {
  English = "en",
  Spanish = "es",
  German = "de",
}

export enum SupportedCountries {
  UnitedStates = "US",
  Canada = "CA",
  Mexico = "MX",
  Argentina = "AR",
  Colombia = "CO",
  Ecuador = "EC",
  Brazil = "BR",
  Peru = "PE",
  CostaRica = "CR",
  ElSalvador = "SV",
  Guatemala = "GT",
  DominicanRepublic = "DO",
  Nicaragua = "NI",
}
