import clsx from "clsx";
import { Table } from "flowbite-react";
import { HeaderType, TableProps } from "../../../types/common/tableTypes";
import { ProcessContent } from "./utils";
import { useRouter } from "next/navigation";
import { Checkbox } from "@nextui-org/react";
import Icon from "@/components/library/Icon";

function LoadingSkeletonRow<T extends string>(headers: HeaderType<T>[]) {
  return (
    <Table.Row>
      {headers.map((h, i) => (
        <Table.Cell key={"thd_" + i}>
          <div
            className={clsx(
              "h-2.5",
              "bg-gray-200",
              "rounded-full",
              "w-24",
              "animate-pulse"
            )}
          ></div>
        </Table.Cell>
      ))}
    </Table.Row>
  );
}

export default function TableComponent<T extends string>({
  data,
  headers,
  commonTranslation: ct,
  country,
  customRender,
  showCheckboxes,
  selectedItems,
  setSelectedItems,
  noDataText,
  noDataIcons,
  keyFormatter,
  showIndex,
  cellClassName,
}: TableProps<T>) {
  const router = useRouter();

  return (
    <div>
      <Table striped>
        <Table.Head>
          {showCheckboxes && (
            <Table.HeadCell
              className={clsx(
                "pl-4",
                "!pr-0",
                "max-w-[32px]",
                "[&>div]:px-0",
                cellClassName
              )}
            >
              <Checkbox
                className={clsx("p-1", "mx-auto")}
                isSelected={selectedItems?.length === data?.length}
                onChange={(e) => {
                  if (!setSelectedItems || !selectedItems) {
                    return;
                  }

                  if (e.target.checked) {
                    setSelectedItems(data?.map((d) => d.id as string) || []);
                  } else {
                    setSelectedItems([]);
                  }
                }}
              />
            </Table.HeadCell>
          )}
          {headers.map((h) => (
            <Table.HeadCell key={h.name} className={cellClassName}>
              {h.label}
            </Table.HeadCell>
          ))}
        </Table.Head>
        {data && data.length > 0 && (
          <Table.Body className="divide-y">
            {data.map((d, di) => (
              <Table.Row
                key={keyFormatter ? keyFormatter(d) : `r_${di}`}
                onClick={(e) => {
                  if (!d.action) {
                    return;
                  }

                  var element = e.target as HTMLElement;
                  if (element.tagName === "TD" || element.tagName === "TR") {
                    router.push(d.action);
                  }
                }}
                className={clsx({
                  "cursor-pointer": d.action,
                  "hover:bg-orange-50": d.action,
                })}
              >
                {showCheckboxes && d.id && (
                  <Table.Cell
                    className={clsx(
                      "pl-4",
                      "!pr-0",
                      "max-w-[32px]",
                      "[&>div]:px-0",
                      cellClassName
                    )}
                  >
                    {showIndex ? (
                      <div className={clsx("text-center")}>{di + 1}</div>
                    ) : (
                      ""
                    )}
                    <Checkbox
                      className={clsx("mx-auto", "p-1")}
                      isSelected={!!selectedItems?.includes(d.id)}
                      onChange={(e) => {
                        if (!setSelectedItems || !selectedItems || !d.id) {
                          return;
                        }

                        if (e.target.checked) {
                          setSelectedItems(
                            [...selectedItems, d.id].filter(
                              (item, index, array) =>
                                array.indexOf(item) === index
                            )
                          );
                        } else {
                          setSelectedItems(
                            selectedItems.filter((item) => item !== d.id)
                          );
                        }
                      }}
                    />
                  </Table.Cell>
                )}
                {headers.map((h, hi) =>
                  ProcessContent(
                    `hc_${di}_${hi}`,
                    h,
                    d,
                    true,
                    ct,
                    country,
                    customRender,
                    cellClassName
                  )
                )}
              </Table.Row>
            ))}
          </Table.Body>
        )}
        {!data && (
          <Table.Body className="divide-y">
            {LoadingSkeletonRow(headers)}
            {LoadingSkeletonRow(headers)}
            {LoadingSkeletonRow(headers)}
            {LoadingSkeletonRow(headers)}
            {LoadingSkeletonRow(headers)}
          </Table.Body>
        )}
      </Table>
      {data?.length !== undefined && data?.length <= 0 && (
        <p className={clsx("text-center", "p-4")}>
          {noDataIcons ? noDataIcons : <Icon icon="phone-slash" />}
          <span className={clsx("mx-4")}>
            {noDataText ? noDataText : "No Calls Found"}
          </span>
          {noDataIcons ? noDataIcons : <Icon icon="phone-slash" />}
        </p>
      )}
    </div>
  );
}
